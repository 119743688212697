class SubscriberPeerConnectionQueue {
  constructor() {
    this.queue = [];
    this.isProcessing = false;
  }

  enqueue(process) {
    if (this.isProcessing) {
      this.queue.push(process);
    } else {
      this.isProcessing = true;
      process();
    }
  }

  dequeueAndProcessNext() {
    const nextProcess = this.queue.shift();
    if (nextProcess) {
      nextProcess();
    } else {
      this.isProcessing = false;
    }
  }
}

export default SubscriberPeerConnectionQueue;
