// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-underscore-dangle, no-use-before-define */
import eventing from '../helpers/eventing';
import eventNames from '../helpers/eventNames';
import domState from '../helpers/domState';
import EventsFactory from './events';

const Events = EventsFactory();

// Helper to synchronise several startup tasks and then dispatch a unified
// 'envLoaded' event.

function EnvironmentLoader() {
  const environmentLoader = this;
  eventing(environmentLoader);

  function isReady() {
    return (
      domState.isDomLoaded() &&
      !domState.isDomUnloaded()
    );
  }

  function onLoaded() {
    if (isReady()) {
      environmentLoader.dispatchEvent(
        new Events.EnvLoadedEvent(eventNames.ENV_LOADED)
      );
    }
  }

  function onDomReady() {
    domState.whenUnloaded.then(onDomUnload);
    onLoaded();
  }

  function onDomUnload() {
    environmentLoader.dispatchEvent(
      new Events.EnvLoadedEvent(eventNames.ENV_UNLOADED)
    );
  }

  domState.whenLoaded.then(onDomReady);

  this.onLoad = function (cb, context) {
    if (isReady()) {
      cb.call(context);
      return;
    }

    environmentLoader.on(eventNames.ENV_LOADED, cb, context);
  };

  this.onUnload = function (cb, context) {
    if (this.isUnloaded()) {
      cb.call(context);
      return;
    }

    environmentLoader.on(eventNames.ENV_UNLOADED, cb, context);
  };

  this.isUnloaded = function () {
    return domState.isDomUnloaded();
  };
}

export default new EnvironmentLoader();
