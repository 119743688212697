import prependProxyToUrlIfNeeded from '../../../helpers/proxyUrlHelper';
import staticConfigFactory from '../../../helpers/StaticConfig';

const defaultStaticConfig = staticConfigFactory().onlyLocal();

// Returns URL of CDN on which ML assets are hosted
const getCdnUrl = (staticConfig, proxyUrl) => {
  const { apiUrl } = staticConfig;
  const isProd = apiUrl.includes('anvil.opentok.com');
  let host = isProd ? 'https://static.opentok.com' : 'https://www.dev.tokbox.com';

  if (proxyUrl) {
    if (proxyUrl.includes('euproxy.opentok.com')) {
      host = 'https://static-eu.opentok.com';
    } else {
      host = prependProxyToUrlIfNeeded(host, proxyUrl);
    }
  }

  return `${host}/ml-transformers/v4.0.0`;
};

// Default configuration that may be shared by all ML transforms
const DefaultConfig = {
  getConfig: (deps = {}) => {
    const {
      staticConfig = defaultStaticConfig,
      proxyUrl,
    } = deps;

    const cdnUrl = getCdnUrl(staticConfig, proxyUrl);

    return {
      mediapipeBaseAssetsUri: `${cdnUrl}`,
      wasmAssetUriPath: `${cdnUrl}/`,
      tfliteAssetUriPath: `${cdnUrl}/`,
    };
  },
};

export default DefaultConfig;
