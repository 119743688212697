/* eslint-disable no-underscore-dangle */
import isEqual from 'lodash/isEqual';
import EventEmitter from 'events';
import createTimeBasedCongestionLevelDefault from './congestionLevelCalculators/timeBasedCongestionLevel';
import createBasicCongestionLevelDefault from './congestionLevelCalculators/basicCongestionLevel';
import createCongestionLevelStatsDefault from './congestionLevelStats';
import createMovingAverageTrackerDefault from './exponentialMovingAverageTracker';
import getFairQualityBandwidthForResolutionDefault from './getFairQualityBandwidthForResolution';
import { LOW } from './congestionLevels';
import createCongestionDataCsvHelper from './congestionDataCsvHelper';

const createCongestionLevelEstimator = (deps = {}) => {
  const ee = new EventEmitter();
  const {
    getStats,
    createMovingAverageTracker = createMovingAverageTrackerDefault,
    createTimeBasedCongestionLevel = createTimeBasedCongestionLevelDefault,
    createBasicCongestionLevel = createBasicCongestionLevelDefault,
    createCongestionLevelStats = createCongestionLevelStatsDefault,
    getFairQualityBandwidthForResolution = getFairQualityBandwidthForResolutionDefault,
  } = deps;

  const congestionLevelTimeBased = createTimeBasedCongestionLevel();
  const congestionLevelBasic = createBasicCongestionLevel();
  const audioPacketLossStats = createMovingAverageTracker();
  const videoPacketLossStats = createMovingAverageTracker();
  const bandwidthStats = createMovingAverageTracker();
  let resolution;
  const congestionDataCsvHelper = createCongestionDataCsvHelper();

  const getTimeBasedCongestionLevel = () => {
    if (audioPacketLossStats.getDataPointCount() === 0) {
      return LOW;
    }
    return congestionLevelTimeBased.getLevel({
      audioPacketLoss: audioPacketLossStats.getMovingAverageValue(),
    });
  };

  const getBasicCongestionLevel = () => {
    if (bandwidthStats.getDataPointCount() === 0
    || audioPacketLossStats.getDataPointCount() === 0) {
      return LOW;
    }
    return congestionLevelBasic.getLevel({
      bandwidth: bandwidthStats.getMovingAverageValue(),
      audioPacketLoss: audioPacketLossStats.getMovingAverageValue(),
      bandwidthFairThreshold: getFairQualityBandwidthForResolution(resolution),
    });
  };

  const addLoggingDataPoint = ({ audioPacketLoss, videoPacketLoss, bandwidth }) => {
    congestionDataCsvHelper.addData({
      timestamp: Date.now(),
      rawAudioPL: audioPacketLoss.toFixed(3),
      rawVideoPL: videoPacketLoss.toFixed(3),
      rawBW: parseInt(bandwidth, 10),
      videoWidth: resolution.width,
      videoHeight: resolution.height,
      avgAudioPL: audioPacketLossStats.getMovingAverageValue().toFixed(3),
      avgVideoPL: videoPacketLossStats.getMovingAverageValue().toFixed(3),
      avgBW: parseInt(bandwidthStats.getMovingAverageValue(), 10),
    });
  };

  const getAlgorithmName = ({ timeLevel, basicLevel }) => {
    if (basicLevel === timeLevel) {
      return 'basic,timeBased';
    } else if (basicLevel > timeLevel) {
      return 'basic';
    }
    return 'timeBased';
  };

  const getLoggingMetadata = ({ congestionLevel, timeLevel, basicLevel }) => {
    if (congestionLevel === LOW) {
      return;
    }
    // eslint-disable-next-line consistent-return
    return {
      resolution,
      algorithm: getAlgorithmName({ timeLevel, basicLevel }),
      videoPacketLoss: videoPacketLossStats.getMovingAverageValue(),
      audioPacketLoss: audioPacketLossStats.getMovingAverageValue(),
      bandwidth: bandwidthStats.getMovingAverageValue(),
      payload: congestionDataCsvHelper.getCsvString(),
    };
  };

  const getCongestionLevel = () => {
    const timeLevel = getTimeBasedCongestionLevel();
    const basicLevel = getBasicCongestionLevel();
    const congestionLevel = Math.max(
      timeLevel, basicLevel
    );

    ee.emit('congestionLevel', congestionLevel, getLoggingMetadata({ congestionLevel, timeLevel, basicLevel }));
  };

  const onStatsAvailable = ({ audioPacketLoss, videoPacketLoss, bandwidth, videoResolution }) => {
    if (resolution && !isEqual(resolution, videoResolution)) {
      audioPacketLossStats.reset();
      videoPacketLossStats.reset();
      bandwidthStats.reset();
    }
    resolution = videoResolution;
    bandwidthStats.addValue(bandwidth);
    videoPacketLossStats.addValue(videoPacketLoss);
    audioPacketLossStats.addValue(audioPacketLoss);
    addLoggingDataPoint({ audioPacketLoss, videoPacketLoss, bandwidth });
    getCongestionLevel();
  };

  const congestionLevelStats = createCongestionLevelStats(getStats);
  congestionLevelStats.on('statsAvailable', (stats) => {
    onStatsAvailable(stats);
  });

  return Object.assign(ee, {
    start() {
      congestionLevelStats.start();
    },
    stop() {
      congestionLevelStats.stop();
      congestionDataCsvHelper.reset();
    },
  });
};

export default createCongestionLevelEstimator;
