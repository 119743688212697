// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-cond-assign, no-underscore-dangle, no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
import eventing from '../../helpers/eventing';
import createLogger from '../../helpers/log';

const logging = createLogger('DataChannel');

// Wraps up a native RTCDataChannelEvent object for the message event. This is
// so we never accidentally leak the native DataChannel.
//
// @constructor
// @private
//
//
const DataChannelMessageEvent = function (event) {
  this.data = event.data;
  this.source = event.source;
  this.lastEventId = event.lastEventId;
  this.origin = event.origin;
  this.timeStamp = event.timeStamp;
  this.type = event.type;
  this.ports = event.ports;
  this.path = event.path;
};

// DataChannel is a wrapper of the native browser RTCDataChannel object.
//
// It exposes an interface that is very similar to the native one except
// for the following differences:
//  * eventing is handled in a way that is consistent with the OpenTok API
//  * calls to the send method that occur before the channel is open will be
//    buffered until the channel is open (as opposed to throwing an exception)
//
// By design, there is (almost) no direct access to the native object. This is to ensure
// that we can control the underlying implementation as needed.
//
// NOTE: IT TURNS OUT THAT FF HASN'T IMPLEMENT THE LATEST PUBLISHED DATACHANNELS
// SPEC YET, SO THE INFO ABOUT maxRetransmitTime AND maxRetransmits IS WRONG. INSTEAD
// THERE IS A SINGLE PROPERTY YOU PROVIDE WHEN CREATING THE CHANNEL WHICH CONTROLS WHETHER
// THE CHANNEL IS RELAIBLE OF NOT.
//
// Two properties that will have a large bearing on channel behaviour are maxRetransmitTime
// and maxRetransmits. Descriptions of those properties are below. They are set when creating
// the initial native RTCDataChannel.
//
// maxRetransmitTime of type unsigned short, readonly , nullable
//   The RTCDataChannel.maxRetransmitTime attribute returns the length of the time global
//   (in milliseconds) during which retransmissions may occur in unreliable mode, or null if
//   unset. The attribute MUST return the value to which it was set when the RTCDataChannel was
//   created.
//
// maxRetransmits of type unsigned short, readonly , nullable
//   The RTCDataChannel.maxRetransmits attribute returns the maximum number of retransmissions
//   that are attempted in unreliable mode, or null if unset. The attribute MUST return the value
//   to which it was set when the RTCDataChannel was created.
//
// @reference http://www.w3.org/TR/webrtc/#peer-to-peer-data-api
//
// @param [RTCDataChannel] dataChannel A native data channel.
//
//
// @constructor
// @private
//
export default function DataChannel(dataChannel) {
  const api = {};

  // / Private Data

  const bufferedMessages = [];

  const qosData = {
    sentMessages: 0,
    recvMessages: 0,
  };

  // / Private API

  const bufferMessage = function bufferMessage(data) {
    bufferedMessages.push(data);
    return api;
  };

  const sendMessage = function sendMessage(data) {
    dataChannel.send(data);
    qosData.sentMessages++;

    return api;
  };

  const flushBufferedMessages = function flushBufferedMessages() {
    let data;

    while ((data = bufferedMessages.shift())) {
      api.send(data);
    }
  };

  const onOpen = function onOpen() {
    api.send = sendMessage;
    flushBufferedMessages();
  };

  const onClose = function onClose(event) {
    api.send = bufferMessage;
    api.trigger('close', event);
  };

  const onError = function onError(event) {
    logging.error(event);
  };

  const onMessage = function onMessage(domEvent) {
    const event = new DataChannelMessageEvent(domEvent);
    qosData.recvMessages++;

    api.trigger('message', event);
  };

  // / Public API

  eventing(api, true);

  api.label = dataChannel.label;
  api.id = dataChannel.id;
  // api.maxRetransmitTime = dataChannel.maxRetransmitTime;
  // api.maxRetransmits = dataChannel.maxRetransmits;
  api.reliable = dataChannel.reliable;
  api.negotiated = dataChannel.negotiated;
  api.ordered = dataChannel.ordered;
  api.protocol = dataChannel.protocol;
  api._channel = dataChannel;

  api.close = function () {
    dataChannel.close();
  };

  api.equals = function (label, options) {
    if (api.label !== label) { return false; }

    for (const key in options) {
      if (options.hasOwnProperty(key)) {
        if (api[key] !== options[key]) {
          return false;
        }
      }
    }

    return true;
  };

  api.getQosData = function () {
    return qosData;
  };

  // Send initially just buffers all messages until
  // the channel is open.
  api.send = bufferMessage;

  // / Init
  dataChannel.addEventListener('open', onOpen, false);
  dataChannel.addEventListener('close', onClose, false);
  dataChannel.addEventListener('error', onError, false);
  dataChannel.addEventListener('message', onMessage, false);

  return api;
}
