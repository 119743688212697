import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import ExceptionCodes from './exception_codes';
import otErrorFactory from '../helpers/otError';
import Errors from './Errors';
import env from '../helpers/env';
import shouldUsePlanBSDP from '../helpers/shouldUsePlanBSDP';
import SDPHelpers from './peer_connection/sdp_helpers';
import createWindowMock from '../helpers/createWindowMock';

const windowMock = createWindowMock(global);
const otError = otErrorFactory();

function filterCodecList(codecs) {
  // Chromium uses the payload 'AV1X' pre M95 and 'AV1' after
  return uniq(difference(codecs, ['AV1X', 'AV1', 'VP9', 'rtx', 'red', 'ulpfec', 'H265', 'X-H264UC', 'x-ulpfecuc']));
}

/**
 * Gets the list of supported codecs for encoding and decoding video streams.
 * <p>
 * The following example gets the list of supported codecs for encoding and
 * decoding video streams:
 * <pre>
 * (async () => {
 *   try {
 *     const supportedCodecs = await OT.getSupportedCodecs();
 *     if (supportedCodecs.videoEncoders.indexOf('H264') < 0 &&
 *         supportedCodecs.videoDecoders.indexOf('H264') < 0) {
 *       // The client does not support encoding or decoding H264.
 *       // Let's recommend using a different browser.
 *     }
 *   } catch(err) {
 *     console.log(err);
 *   }
 * })();
 * </pre>
 * <p>
 * You can use the lists of supported video codecs to determine whether the client
 * supports video, depending on the preferred video codec and the type of session being used.
 * You can set the preferred video codec on the Project page of your
 * <a href="https://tokbox.com/account/">Vonage Video API account</a>. In
 * <a href="https://tokbox.com/developer/guides/create-session/#media-mode">routed sessions</a>
 * (sessions that use the OpenTok Media Router), the preferred video codec is used for all
 * clients in the session. In relayed sessions, clients send streams directly to one another,
 * and each publishing-subscribing pair tries to find a common video codec that they can both use.
 * In this regard, the video codec used by the pair in a relayed session may be different from
 * the preferred video codec setting. For more information see the OpenTok
 * <a href="https://tokbox.com/developer/guides/codecs/">Video codecs</a> developer guide.
 *
 * @return {Promise} A promise that is resolved when the list of supported codecs is ready.
 * The promise is resolved (on success) with an object that has two properties:
 * <code>videoDecoders</code>, an array of supported video codecs for decoding, and
 * <code>videoEncoders</code>, an array of supported video codecs for encoding, such as:
 * <pre>
 * {
 *    videoDecoders: ['H264', 'VP8'],
 *    videoEncoders: ['H264', 'VP8']
 * }
 * </pre>
 * <p>
 * The promise is rejected if there is an error in getting the list.
 *
 * @method OT.getSupportedCodecs
 * @memberof OT
 */
const getSupportedCodecs = async () => {
  if (typeof window === 'undefined') {
    // We are not in a browser, probably Node. Therefore we probably don't support
    // any video codecs.
    return { videoEncoders: [], videoDecoders: [] };
  }

  const RTCPeerConnection = windowMock.RTCPeerConnection;

  if (window.RTCRtpSender && 'getCapabilities' in window.RTCRtpSender) {
    let codecs = window.RTCRtpSender.getCapabilities('video').codecs
      .map(c => c.mimeType || c.name) // Edge/ORTC does not support mimeType
      .map(mimeTypeOrName => mimeTypeOrName.replace('video/', ''));
    codecs = filterCodecList(codecs);
    return { videoEncoders: codecs, videoDecoders: codecs };
  }

  // Chrome got support for HTMLCanvasElement#captureStream in version 51
  if (env.name === 'Chrome' && !('captureStream' in window.HTMLCanvasElement.prototype)) {
    return { videoEncoders: ['VP8'], videoDecoders: ['VP8'] };
  }

  if (env.isLegacyEdge) {
    return { videoEncoders: ['H264', 'VP8'], videoDecoders: ['H264', 'VP8'] };
  }

  const canvas = window.document.createElement('canvas');
  // Need to make a 2d context for captureStream to work, even though it's not used.
  canvas.getContext('2d');
  const stream = canvas.captureStream();

  let localPc;

  try {
    const pcConfig = {
      iceServers: [],
    };
    if (shouldUsePlanBSDP()) {
      pcConfig.sdpSemantics = 'plan-b';
    }
    localPc = new RTCPeerConnection(pcConfig);

    if (localPc.addTrack) {
      localPc.addTrack(stream.getVideoTracks()[0], stream);
    } else if (localPc.addStream) {
      localPc.addStream(stream);
    }

    const offer = await localPc.createOffer();
    let codecs = SDPHelpers.getCodecs(offer.sdp, 'video') || [];
    codecs = filterCodecList(codecs);

    return { videoEncoders: codecs, videoDecoders: codecs };
  } catch (err) {
    throw otError(
      Errors.UNABLE_TO_ACCESS_MEDIA_ENGINE,
      new Error('OT.getSupportedCodecs was not able to get the list of supported codecs'),
      ExceptionCodes.UNABLE_TO_ACCESS_MEDIA_ENGINE
    );
  } finally {
    localPc.close();
  }
};

export default getSupportedCodecs;
