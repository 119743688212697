// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable  prefer-rest-params, no-param-reassign */
/* eslint-disable no-underscore-dangle */
import isEmpty from 'lodash/isEmpty';

const observersFactory = (ElementCollection) => {
  let sizeObserverCount = 0;
  let nodeRemovalObserverCount = 0;

  const observeStyleChanges = function observeStyleChanges(element, stylesToObserve, onChange) {
    const oldStyles = {};

    const getStyle = function getStyle(style) {
      switch (style) {
        case 'width':
          return new ElementCollection(element).width();

        case 'height':
          return new ElementCollection(element).height();

        default:
          return new ElementCollection(element).css(style);
      }
    };

    // get the inital values
    stylesToObserve.forEach((style) => {
      oldStyles[style] = getStyle(style);
    });

    const observer = new global.MutationObserver((mutations) => {
      const changeSet = {};

      mutations.forEach((mutation) => {
        if (mutation.attributeName !== 'style') {
          return;
        }

        const isHidden = new ElementCollection(element).isDisplayNone();

        stylesToObserve.forEach((style) => {
          if (isHidden && (style === 'width' || style === 'height')) {
            return;
          }

          const newValue = getStyle(style);

          if (newValue !== oldStyles[style]) {
            changeSet[style] = [oldStyles[style], newValue];
            oldStyles[style] = newValue;
          }
        });
      });

      if (!isEmpty(changeSet)) {
        // Do this after so as to help avoid infinite loops of mutations.
        setTimeout(() => {
          onChange.call(null, changeSet);
        });
      }
    });

    observer.observe(element, {
      attributes: true,
      attributeFilter: ['style'],
      childList: false,
      characterData: false,
      subtree: false,
    });

    return observer;
  };

  const observeNodeOrChildNodeRemoval = function observeNodeOrChildNodeRemoval(element, onChange) {
    const observer = new global.MutationObserver((mutations) => {
      let removedNodes = [];

      mutations.forEach((mutation) => {
        if (mutation.removedNodes.length) {
          removedNodes = removedNodes.concat(Array.prototype.slice.call(mutation.removedNodes));
        }
      });

      if (removedNodes.length) {
        // Do this after so as to help avoid infinite loops of mutations.
        setTimeout(() => {
          onChange(new ElementCollection(removedNodes));
        });
      }
    });

    observer.observe(element, {
      attributes: false,
      childList: true,
      characterData: false,
      subtree: true,
    });

    nodeRemovalObserverCount++;

    // Intercept the native disconnect method so we can keep a valid count
    // of the currently active node removal observers
    const nativeDisconnect = observer.disconnect;
    observer.disconnect = function () {
      nativeDisconnect.apply(observer, arguments);
      nodeRemovalObserverCount--;
    };

    return observer;
  };

  const observeSize = function (element, onChange) {
    let previousSize = {
      width: 0,
      height: 0,
    };

    const interval = setInterval(() => {
      const rect = element.getBoundingClientRect();
      if (previousSize.width !== rect.width || previousSize.height !== rect.height) {
        onChange(rect, previousSize);
        previousSize = {
          width: rect.width,
          height: rect.height,
        };
      }
    }, 1000 / 5);

    sizeObserverCount++;

    return {
      disconnect() {
        clearInterval(interval);
        sizeObserverCount--;
      },
    };
  };

  // Allows an +onChange+ callback to be triggered when specific style properties
  // of +element+ are notified. The callback accepts a single parameter, which is
  // a hash where the keys are the style property that changed and the values are
  // an array containing the old and new values ([oldValue, newValue]).
  //
  // Width and Height changes while the element is display: none will not be
  // fired until such time as the element becomes visible again.
  //
  // This function returns the MutationObserver itself. Once you no longer wish
  // to observe the element you should call disconnect on the observer.
  //
  // Observing changes:
  //  // observe changings to the width and height of object
  //  dimensionsObserver = OTHelpers(object).observeStyleChanges(,
  //                                                    ['width', 'height'], function(changeSet) {
  //      OT.debug("The new width and height are " +
  //                      changeSet.width[1] + ',' + changeSet.height[1]);
  //  });
  //
  // Cleaning up
  //  // stop observing changes
  //  dimensionsObserver.disconnect();
  //  dimensionsObserver = null;
  //
  ElementCollection.prototype.observeStyleChanges = function (stylesToObserve, onChange) {
    const observers = [];

    this.forEach((element) => {
      observers.push(
        observeStyleChanges(element, stylesToObserve, onChange)
      );
    });

    return observers;
  };

  // trigger the +onChange+ callback whenever
  // 1. +element+ is removed
  // 2. or an immediate child of +element+ is removed.
  //
  // This function returns the MutationObserver itself. Once you no longer wish
  // to observe the element you should call disconnect on the observer.
  //
  // Observing changes:
  //  // observe changings to the width and height of object
  //  nodeObserver = OTHelpers(object).observeNodeOrChildNodeRemoval(function(removedNodes) {
  //      OT.debug("Some child nodes were removed");
  //      removedNodes.forEach(function(node) {
  //          OT.debug(node);
  //      });
  //  });
  //
  // Cleaning up
  //  // stop observing changes
  //  nodeObserver.disconnect();
  //  nodeObserver = null;
  //
  ElementCollection.prototype.observeNodeOrChildNodeRemoval = function (onChange) {
    const observers = [];

    this.forEach((element) => {
      observers.push(
        observeNodeOrChildNodeRemoval(element, onChange)
      );
    });

    return observers;
  };

  // trigger the +onChange+ callback whenever the width or the height of the element changes
  //
  // Once you no longer wish to observe the element you should call disconnect on the observer.
  //
  // Observing changes:
  //  // observe changings to the width and height of object
  //  sizeObserver = OTHelpers(object).observeSize(function(newSize, previousSize) {
  //      OT.debug("The new width and height are " +
  //                      newSize.width + ',' + newSize.height);
  //  });
  //
  // Cleaning up
  //  // stop observing changes
  //  sizeObserver.disconnect();
  //  sizeObserver = null;
  //
  ElementCollection.prototype.observeSize = function (onChange) {
    const observers = [];

    this.forEach((element) => {
      observers.push(
        observeSize(element, onChange)
      );
    });

    return observers;
  };

  // @remove
  ElementCollection._attachToOTHelpers.observeStyleChanges = function (
    element,
    stylesToObserve,
    onChange
  ) {
    return new ElementCollection(element).observeStyleChanges(stylesToObserve, onChange)[0];
  };

  // @remove
  ElementCollection._attachToOTHelpers.observeNodeOrChildNodeRemoval = function (
    element,
    onChange
  ) {
    return new ElementCollection(element).observeNodeOrChildNodeRemoval(onChange)[0];
  };

  // We expose observer counts for testing purposes.
  if (!ElementCollection.__testOnly) {
    ElementCollection.__testOnly = {};
  }

  ElementCollection.__testOnly.sizeObserverCount = function () {
    return sizeObserverCount;
  };

  ElementCollection.__testOnly.nodeRemovalObserverCount = function () {
    return nodeRemovalObserverCount;
  };
};

export default observersFactory;
