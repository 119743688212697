/* eslint-disable no-underscore-dangle */
import * as sframeDefault from 'sframe';
import env from '../helpers/env';

let sframe = { SFrame: {} };
// SFrame lib uses EventTarget that is not available in Node.js, which makes travis to fail.
// As a workaround, we only require the sframe lib if the environment is not Node.
if (!env.isNode) {
  sframe = sframeDefault;
}
const { SFrame } = sframe;
const createClient = senderId => SFrame.createClient(senderId, { skipVp8PayloadHeader: true });

class SFrameClientStore {
  constructor() {
    this._clientStore = {};
  }

  async createSender(senderId = 0) {
    if (this.getSender(senderId)) {
      return this.getSender(senderId);
    }

    if (!this._clientStore[senderId]) {
      this._clientStore[senderId] = {};
    }

    this._clientStore[senderId].sender = await createClient(senderId);

    return this.getSender(senderId);
  }

  async createReceiver(senderId) {
    if (this.getReceiver(senderId)) {
      return this.getReceiver(senderId);
    }

    if (!this._clientStore[senderId]) {
      this._clientStore[senderId] = {};
    }

    this._clientStore[senderId].receiver = await createClient(senderId);

    return this.getReceiver(senderId);
  }

  getSender(senderId) {
    return this._clientStore[senderId]?.sender;
  }

  getReceiver(senderId) {
    return this._clientStore[senderId]?.receiver;
  }

  deleteReceiver(senderId) {
    if (this.getReceiver(senderId)) {
      this._clientStore[senderId].receiver.deleteReceiver(senderId);
      delete this._clientStore[senderId].receiver;
    }
  }

  deleteSender(senderId) {
    delete this._clientStore[senderId]?.sender;
  }
}

export default SFrameClientStore;
