// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, no-void, no-use-before-define, one-var */
/* eslint-disable no-restricted-syntax, no-prototype-builtins, no-underscore-dangle */
import createLogger from '../../../helpers/log';
import env from '../../../helpers/env';
import OTHelpers from '../../../common-js-helpers/OTHelpers';
import sdpHelpers from '../sdp_helpers';
import getProtocolFromPriority from './getProtocolFromPriority';
import getLocalRelayProtocol from './getLocalRelayProtocol';
import getStandardGetStats from '../../../helpers/shouldUseStandardGetStats';
import calculateFrameRate from '../calculate-frame-rate';

const shouldUseStandardGetStats = getStandardGetStats();

const logging = createLogger('QoS');

const requiredPublisherKeys = ['audioCodec', 'audioSentBytes', 'audioSentPackets',
  'audioSentPacketsLost', 'audioRtt', 'videoCodec', 'videoHeight', 'videoHeightInput',
  'videoSentBytes', 'videoFrameRateSent', 'videoSentPackets', 'videoRtt', 'videoSentPacketsLost',
  'videoWidthInput', 'videoWidth', 'srtpCipher'];

const requiredSubscriberKeys = ['audioCodec', 'audioRecvBytes', 'audioRecvPackets',
  'audioRecvPacketsLost', 'videoCodec', 'videoHeight', 'videoRecvBytes', 'videoRecvPackets',
  'videoRecvPacketsLost', 'videoFrameRateReceived', 'videoWidth', 'srtpCipher'];

// There are two implementations of stats parsing in this file.
// 1. For Chrome: Chrome is currently using an older version of the API
// 2. For Firefox: FF is using a version that looks a lot closer to the
//    current spec.
//
// I've attempted to keep the implementations from sharing any code,
// accordingly you'll notice a bunch of duplication between them.
//
// This is acceptable as the goal is to be able to remove each implementation
// as it's no longer needed without any risk of affecting the others. If there
// was shared code between them then each removal would require an audit of
// all the others.

const rawStatsHandler = (pc, stats) => logging.debug(`Raw stats for peer conn ${pc.id}:`, stats);

const getAddress = (res) => {
  const port = res.portNumber || res.port;
  const ip = res.ipAddress || res.address || res.ip;

  return `${ip}:${port}`;
};

// Get Stats using the older API. Used by Chrome up until v58.
export function parseStatsOldAPI(
  peerConnection,
  prevStats,
  currentStats,
  isPublisher,
  completion
) {
  /* this parses a result if there it contains the video bitrate */
  const parseVideoStats = (result) => {
    if (Number(result.stat('googFrameRateSent')) > 0) {
      currentStats.videoSentBytes = Number(result.stat('bytesSent'));
      currentStats.videoSentPackets = Number(result.stat('packetsSent'));
      currentStats.videoSentPacketsLost = Number(result.stat('packetsLost'));
      // googRtt is expressed in ms, and we need to log it always in seconds. See OPENTOK-42624.
      currentStats.videoRtt = Number(result.stat('googRtt')) / 1000;
      currentStats.videoFrameRate = Number(result.stat('googFrameRateInput'));
      currentStats.videoWidth = Number(result.stat('googFrameWidthSent'));
      currentStats.videoHeight = Number(result.stat('googFrameHeightSent'));
      currentStats.videoFrameRateSent = Number(result.stat('googFrameRateSent'));
      currentStats.videoWidthInput = Number(result.stat('googFrameWidthInput'));
      currentStats.videoHeightInput = Number(result.stat('googFrameHeightInput'));
      currentStats.videoCodec = result.stat('googCodecName');
      currentStats.videoBandwidthLimitedResolution = result.stat('googBandwidthLimitedResolution')
       === 'true';
      currentStats.cpuLimitedResolution = result.stat('googCpuLimitedResolution') === 'true';
    } else if (Number(result.stat('googFrameRateReceived')) > 0) {
      currentStats.videoRecvBytes = Number(result.stat('bytesReceived'));
      currentStats.videoRecvPackets = Number(result.stat('packetsReceived'));
      currentStats.videoRecvPacketsLost = Number(result.stat('packetsLost'));
      currentStats.videoFrameRate = Number(result.stat('googFrameRateOutput'));
      currentStats.videoFrameRateReceived = Number(result.stat('googFrameRateReceived'));
      currentStats.videoFrameRateDecoded = Number(result.stat('googFrameRateDecoded'));
      currentStats.videoWidth = Number(result.stat('googFrameWidthReceived'));
      currentStats.videoHeight = Number(result.stat('googFrameHeightReceived'));
      currentStats.videoCodec = result.stat('googCodecName');
    }

    return null;
  };

  const parseAudioStats = (result) => {
    if (Number(result.stat('audioInputLevel')) > 0) {
      currentStats.audioSentPackets = Number(result.stat('packetsSent'));
      currentStats.audioSentPacketsLost = Number(result.stat('packetsLost'));
      currentStats.audioSentBytes = Number(result.stat('bytesSent'));
      currentStats.audioCodec = result.stat('googCodecName');
      // googRtt is expressed in ms, and we need to log it always in seconds. See OPENTOK-42624.
      currentStats.audioRtt = Number(result.stat('googRtt')) / 1000;
    } else if (Number(result.stat('audioOutputLevel')) > 0) {
      currentStats.audioRecvPackets = Number(result.stat('packetsReceived'));
      currentStats.audioRecvPacketsLost = Number(result.stat('packetsLost'));
      currentStats.audioRecvBytes = Number(result.stat('bytesReceived'));
      currentStats.audioCodec = result.stat('googCodecName');
    }
  };

  const parseStatsReports = (stats) => {
    if (stats.result) {
      const resultList = stats.result();
      rawStatsHandler(peerConnection, resultList);

      const getCandidate = (type, fromStat) =>
        resultList.filter(x => x.id === fromStat.stat(type))[0];
      const getLocalCandidate = fromStat => getCandidate('localCandidateId', fromStat);
      const getRemoteCandidate = fromStat => getCandidate('remoteCandidateId', fromStat);

      for (let resultIndex = 0; resultIndex < resultList.length; resultIndex++) {
        const result = resultList[resultIndex];

        if (result.stat) {
          if (result.stat('googActiveConnection') === 'true') {
            if (result.stat('googChannelId').indexOf('audio') > -1) {
              currentStats.audioLocalAddress = result.stat('googLocalAddress');
              currentStats.audioRemoteAddress = result.stat('googRemoteAddress');
              currentStats.audioLocalCandidateType = result.stat('googLocalCandidateType');
              currentStats.audioRemoteCandidateType = result.stat('googRemoteCandidateType');
              currentStats.audioTransportType = result.stat('googTransportType');
              currentStats.audioLocalRelayProtocol = getProtocolFromPriority(
                getLocalCandidate(result).stat('priority')
              );
              currentStats.audioRemoteRelayProtocol = getProtocolFromPriority(
                getRemoteCandidate(result).stat('priority')
              );
            } else if (result.stat('googChannelId').indexOf('video') > -1) {
              currentStats.videoLocalAddress = result.stat('googLocalAddress');
              currentStats.videoRemoteAddress = result.stat('googRemoteAddress');
              currentStats.videoLocalCandidateType = result.stat('googLocalCandidateType');
              currentStats.videoRemoteCandidateType = result.stat('googRemoteCandidateType');
              currentStats.videoTransportType = result.stat('googTransportType');
              currentStats.videoLocalRelayProtocol = getProtocolFromPriority(
                getLocalCandidate(result).stat('priority')
              );
              currentStats.videoRemoteRelayProtocol = getProtocolFromPriority(
                getRemoteCandidate(result).stat('priority')
              );
            }
          }

          // Video BWE
          if (Number(result.stat('googAvailableSendBandwidth')) > 0) {
            currentStats.videoSentEstimatedBandwidth = Number(result.stat('googAvailableSendBandwidth'));
          }
          if (Number(result.stat('googAvailableReceiveBandwidth')) > 0) {
            currentStats.videoRecvEstimatedBandwidth = Number(result.stat('googAvailableReceiveBandwidth'));
          }

          parseAudioStats(result);
          parseVideoStats(result);
        }
      }

      // For audio-video publishers in Chrome, there are no corresponding video reports for these

      if ('videoCodec' in currentStats && !currentStats.videoLocalAddress) {
        [
          'LocalAddress',
          'RemoteAddress',
          'LocalCandidateType',
          'RemoteCandidateType',
          'TransportType',
          'LocalRelayProtocol',
          'RemoteRelayProtocol',
        ].forEach((keySuffix) => {
          currentStats[`video${keySuffix}`] = currentStats[`audio${keySuffix}`];
        });
      }
    }

    completion(null, currentStats);
  };

  peerConnection.getStats(parseStatsReports);
}

// Get Stats using the newer API.
export function parseStatsNewAPI(peerConnection,
  prevStats,
  currentStats,
  isPublisher,
  completion) {
  const onStatsError = function onStatsError(error) {
    completion(error);
  };

  const parseAudioStats = (result, strippedType) => {
    if (strippedType === 'outboundrtp') {
      currentStats.audioSentPackets = result.packetsSent;
      currentStats.audioSentPacketsLost = result.packetsLost;
      currentStats.audioSentBytes = result.bytesSent;
    } else if (strippedType === 'inboundrtp') {
      currentStats.audioRecvPackets = result.packetsReceived;
      currentStats.audioRecvPacketsLost = result.packetsLost;
      currentStats.audioRecvBytes = result.bytesReceived;
    }
    // OPENTOK-36308 Safari doesn't have audioCodec but on the publisher side it can be mapped
    // to the SDP.  On the subscriber side, currentStats.audioCodec will remain undefined, and
    // will be set (possibly incorrectly) by picking the first rtpmap line out of the SDP.
    if ((undefined === currentStats.audioCodec) &&
    (['Safari'].indexOf(OTHelpers.env.name) !== -1) && (undefined !== result.codecId)) {
      const { sdp } = peerConnection.remoteDescription.type === 'answer' ?
        peerConnection.remoteDescription : peerConnection.localDescription;
      const lastIndex = result.codecId.lastIndexOf('_');
      const codecsAndCodecMap = sdpHelpers.getCodecsAndCodecMap(sdp, 'audio');
      if ((lastIndex !== -1) && (codecsAndCodecMap !== undefined)) {
        currentStats.audioCodec =
        codecsAndCodecMap.codecMap[result.codecId.substring(lastIndex + 1)];
      }
    }
  };

  const parseOutboundVideoStats = (result) => {
    // Maps the stat of the (canonical) field to a more readable alias
    const fieldToAliasMap = {
      packetsSent: 'videoSentPackets',
      packetsLost: 'videoSentPacketsLost',
      bytesSent: 'videoSentBytes',
      framesPerSecond: 'videoFrameRate',
      framesSent: 'videoFrameRateSent',
      framesReceived: 'videoFramesReceived',
      frameWidth: 'videoWidth',
      frameHeight: 'videoHeight',
    };

    const cummulativeStats = ['videoSentPackets', 'videoSentBytes'];

    // Stores all the defined stats
    for (const field in fieldToAliasMap) {
      if (field in result) {
        const alias = fieldToAliasMap[field];
        if (cummulativeStats.includes(alias) && Number.isSafeInteger(currentStats[alias])) {
          currentStats[alias] += result[field];
        } else {
          currentStats[alias] = result[field];
        }
      }
    }
  };

  const parseVideoStats = (result, strippedType) => {
    const getVideoFrameRate = () => {
      const haveBytes = 'videoSentBytes' in currentStats ? currentStats.videoSentBytes > 0
        : currentStats.videoRecvBytes > 0;
      if (!haveBytes) {
        return 0;
      }

      let videoFrameRate = 0;
      if (env.isFirefox && env.version < 96) {
        videoFrameRate = Number(result.framerateMean);
      } else {
        const framesStat = strippedType === 'outboundrtp' ? 'framesEncoded' : 'framesDecoded';
        currentStats[framesStat] = result[framesStat];
        videoFrameRate = calculateFrameRate({
          currentStatFrames: result[framesStat],
          currentTimestamp: result.timestamp,
          prevStatFrames: prevStats[framesStat],
          prevTimestamp: prevStats.timeStamp || Date.now(),
        });
      }
      return videoFrameRate;
    };

    if (strippedType === 'outboundrtp') {
      if (result.hasOwnProperty('qualityLimitationReason')) {
        currentStats.videoBandwidthLimitedResolution = result.qualityLimitationReason === 'bandwidth';
        currentStats.cpuLimitedResolution = result.qualityLimitationReason === 'cpu';
      }
      parseOutboundVideoStats(result);
    } else if (strippedType === 'inboundrtp') {
      currentStats.videoRecvPackets = result.packetsReceived;
      currentStats.videoRecvPacketsLost = result.packetsLost;
      currentStats.videoRecvBytes = result.bytesReceived;
      currentStats.videoWidth = result.frameWidth;
      currentStats.videoHeight = result.frameHeight;
      currentStats.videoFramesReceived = result.framesReceived;
    }

    // Calculation of videoFrameRate:
    // - For Chromium based browsers:
    //   -- Publisher videoFrameRate: it's calculated in parseOutboundVideoStats().
    //   -- Subscriber videoFrameRate: it's calculated in getVideoFrameRate().
    // - For Firefox <= 95
    //   -- Obtained from frameRateMean stat in getVideoFrameRate() for both inbound and
    //      outbound video reports.
    // - For Firefox >= 96
    //   -- it's calculated in getVideoFrameRate() calculating the frameRate using
    //      on framesEncoded (outbound video) or framesDecoded (inbound video)
    // - For Safari
    //   -- Publisher videoFrameRate: it's calculated in parseOutboundVideoStats().
    //   -- Subscriber videoFrameRate: it's calculated in getVideoFrameRate().
    if ((env.isFirefox && ['outboundrtp', 'inboundrtp'].includes(strippedType)) ||
      (!isPublisher && strippedType === 'inboundrtp')) {
      currentStats.videoFrameRate = getVideoFrameRate();
    }

    // OPENTOK-36308 Safari doesn't have videoCodec but on the publisher side it can be mapped
    // to the SDP.  On the subscriber side, currentStats.videoCodec will remain undefined, and
    // will be set (possibly incorrectly) by picking the first rtpmap line out of the SDP.
    if ((undefined === currentStats.videoCodec) &&
    (['Safari'].indexOf(OTHelpers.env.name) !== -1) && (undefined !== result.codecId)) {
      const { sdp } = peerConnection.remoteDescription.type === 'answer' ?
        peerConnection.remoteDescription : peerConnection.localDescription;
      const lastIndex = result.codecId.lastIndexOf('_');
      const codecsAndCodecMap = sdpHelpers.getCodecsAndCodecMap(sdp, 'video');
      if ((lastIndex !== -1) && (codecsAndCodecMap !== undefined)) {
        currentStats.videoCodec =
        codecsAndCodecMap.codecMap[result.codecId.substring(lastIndex + 1)];
      }
    }
  };

  peerConnection.getStats(null).then((stats) => {
    let localCandidateType,
      remoteCandidateType,
      localAddress,
      localRelayProtocol,
      remoteAddress,
      transportType,
      frameWidth,
      frameHeight;
    const videoRttValues = [];

    // Calculates the average of all the roundTripTime values.
    const getVideoRttAverage = () => {
      if (videoRttValues.length === 0) {
        return null;
      }
      const positiveVideoRtts = videoRttValues.filter(videoRtt => videoRtt > 0);
      const accumulatedVideoRtt = positiveVideoRtts.reduce((acc, videoRtt) => acc + videoRtt, 0);
      const videoRttAverage = positiveVideoRtts.length ?
        accumulatedVideoRtt / positiveVideoRtts.length : 0;
      return videoRttAverage;
    };

    // Converts `Inbound-RTP` to `inboundrtp`
    const getStrippedType = type =>
      type.toLowerCase().replace(/[^a-z]/g, '');

    const statsLoop = (res) => {
      const strippedType = getStrippedType(res.type);
      const lowercaseId = res.id.toLowerCase();

      if (/(in|out)boundrtp$/.test(strippedType)) {
        // Media type may be mapped to either `id` or `kind`
        const isRtp = /rtp/.test(lowercaseId);
        const isAudioRtp = isRtp && /audio/.test(lowercaseId);
        const isVideoRtp = isRtp && /video/.test(lowercaseId);
        const isAudio = isAudioRtp || res.kind === 'audio';
        const isVideo = isVideoRtp || res.kind === 'video';

        if (isAudio) {
          parseAudioStats(res, strippedType);
        } else if (isVideo) {
          parseVideoStats(res, strippedType);
        }

        if (res.hasOwnProperty('roundTripTime')) {
          const type = res.mediaType || res.kind;

          // roundTripTime comes on the rtcp stats and so won't be caught above
          if (type === 'video') {
            videoRttValues.push(res.roundTripTime);
          } else if (type === 'audio') {
            currentStats.audioRtt = res.roundTripTime;
          }
        }

        if (strippedType === 'remoteinboundrtp') {
          if (res.hasOwnProperty('packetsLost')) {
            const { kind } = res;
            const key = `${kind}SentPacketsLost`;

            if (!currentStats[key]) {
              currentStats[key] = 0;
            }

            currentStats[key] += res.packetsLost;
          }
        }
      } else if (strippedType === 'transport') {
        const { selectedCandidatePairId, srtpCipher } = res;

        currentStats.srtpCipher = srtpCipher;

        if (selectedCandidatePairId) {
          localRelayProtocol = getLocalRelayProtocol(selectedCandidatePairId, stats);
        }
      } else if (strippedType === 'localcandidate') {
        localCandidateType = res.candidateType;
        localAddress = getAddress(res);
        transportType = res.protocol || res.transport;
      } else if (strippedType === 'remotecandidate') {
        remoteCandidateType = res.candidateType;
        remoteAddress = getAddress(res);
      } else if (strippedType === 'track' && lowercaseId.indexOf('video') !== 0) {
        frameWidth = res.frameWidth;
        frameHeight = res.frameHeight;
        parseOutboundVideoStats(res);
      } else if (strippedType === 'mediasource') {
        if (res.hasOwnProperty('framesPerSecond')) {
          currentStats.videoFrameRate = res.framesPerSecond;
        }
        if (res.hasOwnProperty('width')) {
          currentStats.videoWidthInput = res.width;
        }
        if (res.hasOwnProperty('height')) {
          currentStats.videoHeightInput = res.height;
        }
      } else if (strippedType === 'candidatepair') {
        if (res.hasOwnProperty('availableOutgoingBitrate')) {
          const estimatedStat = `video${isPublisher ? 'Sent' : 'Recv'}EstimatedBandwidth`;
          currentStats[estimatedStat] = parseInt(res.availableOutgoingBitrate, 10);
        }
      }
    };

    // Firefox <= 45 can't use for of loop OPENTOK-32755
    if (typeof stats[Symbol.iterator] === 'function') {
      rawStatsHandler(peerConnection, Array.from(stats));
      for (const el of stats) {
        const res = Array.isArray(el) ? el[1] : el;
        statsLoop(res);
      }
    } else {
      rawStatsHandler(peerConnection, stats);
      for (const key in stats) {
        if (stats.hasOwnProperty(key)) {
          statsLoop(stats[key]);
        }
      }
    }

    if (peerConnection.currentRemoteDescription) {
      currentStats.useDtx = sdpHelpers.hasSendDtx(peerConnection.currentRemoteDescription.sdp);
    }

    if (isPublisher) {
      currentStats.videoRtt = getVideoRttAverage();
    }

    if (currentStats.audioRecvBytes || currentStats.audioSentBytes) {
      currentStats.audioLocalCandidateType = localCandidateType;
      currentStats.audioLocalAddress = localAddress;
      currentStats.audioRemoteCandidateType = remoteCandidateType;
      currentStats.audioRemoteAddress = remoteAddress;
      currentStats.audioTransportType = transportType;
      currentStats.audioLocalRelayProtocol = localRelayProtocol;

      if (currentStats.audioCodec === undefined) {
        const { sdp } = peerConnection.remoteDescription.type === 'answer' ?
          peerConnection.remoteDescription : peerConnection.localDescription;
        const codecs = sdpHelpers.getCodecs(sdp, 'audio');
        currentStats.audioCodec = codecs[0];
      }
    }

    if (currentStats.videoRecvBytes || currentStats.videoSentBytes) {
      currentStats.videoLocalCandidateType = localCandidateType;
      currentStats.videoLocalAddress = localAddress;
      currentStats.videoRemoteCandidateType = remoteCandidateType;
      currentStats.videoRemoteAddress = remoteAddress;
      currentStats.videoTransportType = transportType;
      currentStats.videoLocalRelayProtocol = localRelayProtocol;
      if (currentStats.videoCodec === undefined) {
        const { sdp } = peerConnection.remoteDescription.type === 'answer' ?
          peerConnection.remoteDescription : peerConnection.localDescription;
        const codecs = sdpHelpers.getCodecs(sdp, 'video');
        currentStats.videoCodec = codecs[0];
      }
    }

    extendStats(currentStats, isPublisher);
    currentStats.videoWidth = currentStats.videoWidth || frameWidth;
    currentStats.videoHeight = currentStats.videoHeight || frameHeight;
    completion(null, currentStats);
  }).catch(onStatsError);
}

export default function parseQOS(peerConnection, prevStats, currentStats, isPublisher, completion) {
  if (shouldUseStandardGetStats) {
    return parseStatsNewAPI(peerConnection, prevStats, currentStats, isPublisher, completion);
  }

  return parseStatsOldAPI(peerConnection, prevStats, currentStats, isPublisher, completion);
}

function extendStats(stats, isPublisher) {
  const requiredKeys = isPublisher ? requiredPublisherKeys : requiredSubscriberKeys;
  requiredKeys.forEach((key) => {
    if (!stats.hasOwnProperty(key)) {
      stats[key] = null;
    }
  });
}
