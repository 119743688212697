export default class {
  constructor() {
    this.mutedMantisAudioTracksInAMR = {};
    this.amrAudioMuted = false;
  }

  replaceTrackInMutedAudioTracks = async (
    peerConnection,
    oldTrack,
    newTrack
  ) => {
    if (
      !!this.mutedMantisAudioTracksInAMR[newTrack.id] &&
      peerConnection?.hasTrack(this.mutedMantisAudioTracksInAMR[newTrack.id])
    ) {
      // If muted, the ID is present, and PC has the cloned track, we don't need to do anything.
      return;
    }
    // If it is not muted, the ID is not present, or the PC does not have the cloned track,
    // we clone the track, disable it, and replace it.
    const newClonedTrack = newTrack.clone();
    newClonedTrack.enabled = false;

    // When a track is cloned, the ID changes but is otherwise identical. Thus,
    // we need to find the old cloned track by the (original, pre-cloned) ID,
    // since we cannot use strict equality to find it, and replace it with a new cloned track.
    const oldClonedTrack = this.mutedMantisAudioTracksInAMR[oldTrack.id];

    try {
      await peerConnection.findAndReplaceTrack(oldClonedTrack, newClonedTrack);
    } catch (error) {
      return;
    }

    // Save it, so it can be restored once unmuted.
    this.mutedMantisAudioTracksInAMR[newTrack.id] = newClonedTrack;
    delete this.mutedMantisAudioTracksInAMR[oldTrack.id];
  };

  unmuteAudioInPeerConnection = async (webRTCStream, peerConnection) => {
    // Replace the cloned muted tracks by the original tracks.
    webRTCStream.getAudioTracks().forEach(async (track) => {
      // Due to the nature of the errors we will only fail here if we previously failed in the
      // _muteAudioInPeerConnection call. Thus, this call will be a noop.
      try {
        await peerConnection.findAndReplaceTrack(
          this.mutedMantisAudioTracksInAMR[track.id],
          track
        );
      } catch (err) {
        return;
      }
      delete this.mutedMantisAudioTracksInAMR[track.id];
    });
    this.amrAudioMuted = false;
  };

  muteAudioInPeerConnection = async (webRTCStream, peerConnection) => {
    if (this.amrAudioMuted) {
      return;
    }
    this.amrAudioMuted = true;
    // Mute all audio tracks for the given PC. Please note we don't want
    // to mute the stream since this can be used by another PC. Thus, we
    // first clone the audio track, and then mute it.
    webRTCStream.getAudioTracks().forEach(async (track) => {
      const audioTrack = track.clone();
      audioTrack.enabled = false;
      // Best effort: if we fail to replace the track, we will keep the original one. In case
      // there is more than one track, we will try to replace as many as possible.
      // Potential errors: replaceTrack not supported in old browsers, negotiation required.
      try {
        await peerConnection.findAndReplaceTrack(track, audioTrack);
      } catch (err) {
        return;
      }
      // Save it, so it can be restored once unmuted.
      this.mutedMantisAudioTracksInAMR[track.id] = audioTrack;
    });
  };

  destroy = () => {
    Object.entries(this.mutedMantisAudioTracksInAMR).forEach(([id, track]) => {
      track?.stop();
      delete this.mutedMantisAudioTracksInAMR[id];
    });
    this.amrAudioMuted = false;
  }
}
