/* eslint-disable no-underscore-dangle */
import AMRStates from './amrStates';

const createAmrStateMachine = () => {
  const amrStateMachine = {
    state: AMRStates.MANTIS,
    pendingTransitions: [],

    isTransitioning() {
      return this.state === AMRStates.TRANSITIONING;
    },

    startTransitionToRelayed() {
      // Transition to relayed is async, so when the transition has started we just change the state
      // to TRANSITIONING.
      this.state = AMRStates.TRANSITIONING;
    },

    completeTransitionTo(state) {
      // Only the transition to p2p is async. We don't call completeTransitionTo for routed.
      if (state === AMRStates.P2P) {
        this._transitionTo(state);
      }
    },

    startTransitionToRouted(processTransition) {
      if (this.state === AMRStates.TRANSITIONING) {
        this.pendingTransitions.push({ to: AMRStates.MANTIS, process: processTransition });
      } else {
        this._transitionTo(AMRStates.MANTIS, processTransition);
      }
    },

    _transitionTo(state, process) {
      if (process) {
        this.state = AMRStates.TRANSITIONING;
        process();
      }

      this.state = state;
      this._onTransitionCompleted();
    },

    _onTransitionCompleted() {
      if (this.pendingTransitions.length) {
        const { to, process } = this.pendingTransitions.shift();
        this._transitionTo(to, process);
      }
    },
  };
  return amrStateMachine;
};

export default createAmrStateMachine;
