/* eslint-disable no-underscore-dangle */
import EventEmitter from 'events';
import * as StatsUtilsDefault from './statsUtils';

const INITIAL_DELAY = 1000;
const INTERVAL = 500;

const createCongestionLevelStats = (getPcStats, deps = {}) => {
  const StatsUtils = deps.StatsUtils || StatsUtilsDefault;
  const ee = new EventEmitter();
  let lastAudioStats;
  let lastVideoStats;

  const resetStats = () => {
    lastAudioStats = undefined;
    lastVideoStats = undefined;
  };

  const getAudioPacketLoss = (parsedStats) => {
    if (lastAudioStats === undefined) {
      lastAudioStats = parsedStats;
    }
    let audioPacketLoss;
    if (parsedStats.audioReportTimestamp !== lastAudioStats.audioReportTimestamp) {
      if (parsedStats.audioFractionLost !== undefined) {
        audioPacketLoss = parsedStats.audioFractionLost;
      } else {
        audioPacketLoss = StatsUtils.getAudioPacketLoss(parsedStats, lastAudioStats);
      }
      lastAudioStats = parsedStats;
    }
    return audioPacketLoss;
  };

  const getVideoPacketLoss = (parsedStats) => {
    if (lastVideoStats === undefined) {
      lastVideoStats = parsedStats;
    }
    let videoPacketLoss;
    if (parsedStats.videoReportTimestamp !== lastVideoStats.videoReportTimestamp) {
      videoPacketLoss = StatsUtils.getVideoPacketLoss(parsedStats, lastVideoStats);
      lastVideoStats = parsedStats;
    }
    return videoPacketLoss;
  };

  const processStats = (stats) => {
    const parsedStats = StatsUtils.parseStats(stats);

    const audioPacketLoss = getAudioPacketLoss(parsedStats);
    const videoPacketLoss = getVideoPacketLoss(parsedStats);

    const { bandwidth, videoResolution } = parsedStats;

    ee.emit('statsAvailable', {
      audioPacketLoss,
      videoPacketLoss,
      bandwidth,
      videoResolution,
    });
  };

  const getStats = () => {
    getPcStats((err, stats) => {
      if (!err) {
        processStats(stats);
      }
    });
  };

  let cancelId;
  return Object.assign(ee, {
    start() {
      if (cancelId) {
        // already started
        return;
      }
      cancelId = setTimeout(() => {
        getStats();
        cancelId = setInterval(() => {
          getStats();
        }, INTERVAL);
      }, INITIAL_DELAY);
    },

    stop() {
    // Note that clearInterval and clearTimeout are interchangeable
      clearInterval(cancelId);
      cancelId = undefined;
      resetStats();
    },
  });
};

export default createCongestionLevelStats;
