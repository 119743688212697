// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-underscore-dangle, no-param-reassign */

import defineProperties from '../../helpers/defineProperties';
import OTHelpers from '../../common-js-helpers/OTHelpers';

const VideoOrientationTransforms = {
  0: 'rotate(0deg)',
  270: 'rotate(90deg)',
  90: 'rotate(-90deg)',
  180: 'rotate(180deg)',
};

// A mixin to create the orientation API implementation on +self+
// +getDomElementCallback+ is a function that the mixin will call when it wants to
// get the native Dom element for +self+.
//
// +initialOrientation+ sets the initial orientation (shockingly), it's currently unused
// so the initial value is actually undefined.
//
export default function canBeOrientatedMixin(
  self,
  getDomElementCallback,
  initialOrientation
) {
  let _orientation = initialOrientation;

  defineProperties(self, {
    isRotated: {
      get() {
        return this.orientation() &&
                  (this.orientation().videoOrientation === 270 ||
                   this.orientation().videoOrientation === 90);
      },
    },

    // @todo I don't think we need vendor prefixes at all anymore.
    orientation: {
      get() { return _orientation; },
      set(orientation) {
        _orientation = orientation;

        const transform = VideoOrientationTransforms[orientation.videoOrientation] ||
                        VideoOrientationTransforms.ROTATED_NORMAL;

        switch (OTHelpers.env.name) {
          case 'Chrome':
          case 'Safari':
            getDomElementCallback().style.webkitTransform = transform;
            break;
          default:
            // The standard version, just Firefox, Opera, and Edge
            getDomElementCallback().style.transform = transform;
        }
        self.trigger('orientationChanged', _orientation);
      },
    },

    // see https://wiki.mozilla.org/WebAPI/AudioChannels
    // The audioChannelType is currently only available in Firefox. This property returns
    // "unknown" in other browser. The related HTML tag attribute is "mozaudiochannel"
    audioChannelType: {
      get() {
        if ('mozAudioChannelType' in this.domElement) {
          return this.domElement.mozAudioChannelType;
        }

        return 'unknown';
      },
      set(type) {
        if ('mozAudioChannelType' in this.domElement) {
          this.domElement.mozAudioChannelType = type;
        }
      },
    },
  });
}
