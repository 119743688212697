/* eslint-disable consistent-return */
import createLogger from '../../helpers/log';
import GetAudioLevelSampler from '../../helpers/audio_level_samplers/get_audio_level_sampler';
import WebAudioAudioLevelSampler from '../../helpers/audio_level_samplers/webaudio_audio_level_sampler';
import hasSynchronizationSourcesCapability from '../../helpers/hasSynchronizationSourcesCapability';
import hasAudioOutputLevelStatCapability from '../../helpers/hasAudioOutputLevelStatCapability';
import hasRemoteStreamsWithWebAudio from '../../helpers/hasRemoteStreamsWithWebAudio';
import audioContextHelper from '../../helpers/audio_context';
import isAudioContext from '../../helpers/is_audio_context';

const getAudioContext = audioContextHelper();

const logging = createLogger('Subscriber');

const audioLevelSamplerFactory = (peerConnection, deps = {}) => {
  const hasGetSynchronizationSupport = deps.hasGetSynchronizationSupport;

  if (!peerConnection) return;

  // prefer the audioLevelSampler (more efficient and better responsiveness)
  if (hasSynchronizationSourcesCapability({ hasGetSynchronizationSupport })) {
    return new GetAudioLevelSampler(peerConnection.getSynchronizationSources);
  }

  if (hasAudioOutputLevelStatCapability()) {
    return new GetAudioLevelSampler(peerConnection.getAudioStats);
  }

  if (hasRemoteStreamsWithWebAudio()) {
    let audioContext;
    try {
      audioContext = deps.audioContext || getAudioContext();
    } catch (e) {
      logging.warn('Failed to get AudioContext()', e);
    }

    if (isAudioContext(audioContext)) {
      return new WebAudioAudioLevelSampler(audioContext);
    }
  }
};

export default audioLevelSamplerFactory;
