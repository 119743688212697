// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-underscore-dangle, no-param-reassign */
import OTHelpers from '../../../common-js-helpers/OTHelpers';

// A mixin to encapsulate the basic widget behaviour. This needs a better name,
// it's not actually a widget. It's actually "Behaviour that can be applied to
// an object to make it support the basic Chrome widget workflow"...but that would
// probably been too long a name.
export default function Widget(widget, options) {
  let _mode;
  const _options = options || {};

  //
  // @param [String] mode
  //      'on', 'off', or 'auto'
  //
  widget.setDisplayMode = function (mode) {
    const newMode = mode || 'auto';
    if (_mode === newMode) { return; }

    OTHelpers.removeClass(this.domElement, `OT_mode-${_mode}`);
    OTHelpers.addClass(this.domElement, `OT_mode-${newMode}`);

    _mode = newMode;
  };

  widget.getDisplayMode = function () {
    return _mode;
  };

  widget.showAfterLoading = function () {
    OTHelpers.removeClass(this.domElement, 'OT_hide-forced');
  };

  widget.hideWhileLoading = function () {
    OTHelpers.addClass(this.domElement, 'OT_hide-forced');
  };

  widget.destroy = function () {
    if (_options.onDestroy) { _options.onDestroy(this.domElement); }
    if (this.domElement) { OTHelpers.removeElement(this.domElement); }

    return widget;
  };

  widget.appendTo = function (parent) {
    // create the element under parent
    this.domElement = OTHelpers.createElement(_options.nodeName || 'div',
      _options.htmlAttributes,
      _options.htmlContent);

    if (_options.onCreate) { _options.onCreate(this.domElement); }

    widget.setDisplayMode(_options.mode);

    if (_options.mode === 'auto') {
      // if the mode is auto we hold the "on mode" for 2 seconds
      // this will let the proper widgets nicely fade away and help discoverability
      OTHelpers.addClass(widget.domElement, 'OT_mode-on-hold');
      setTimeout(() => {
        OTHelpers.removeClass(widget.domElement, 'OT_mode-on-hold');
      }, 2000);
    }

    // add the widget to the parent
    parent.appendChild(this.domElement);

    return widget;
  };
}
