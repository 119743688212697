import SDPHelpers from './sdp_helpers';

const { RECVONLY, INACTIVE } = SDPHelpers.mediaDirections;

const changeMediaDirection = async (peerConnection, direction) => {
  if (!peerConnection) {
    return;
  }

  const offer = await peerConnection.createOffer();
  await peerConnection.setLocalDescription(offer);
  const { sdp } = peerConnection.remoteDescription;
  // replaces the 'a=recvonly' lines to 'a=inactive' or viceversa, depending on active.
  const newSdp = SDPHelpers.changeMediaDirection(sdp, direction);

  peerConnection.setRemoteDescription({
    type: peerConnection.remoteDescription.type,
    sdp: newSdp,
  });
};

/**
 * Changes the RTP media direction by SDP munging
 * @param {Object} peerConnection - the peer connection to apply the change
 * @param {('recvonly' | 'inactive')} direction - The new direction that could be recvonly or
 * inactive. If it's inactive, then the peer connection will stop sending RTP media.
 */
export default {
  changeMediaDirectionToInactive: peerConnection => changeMediaDirection(
    peerConnection, INACTIVE),
  changeMediaDirectionToRecvOnly: peerConnection => changeMediaDirection(
    peerConnection, RECVONLY),
};
