/* eslint-disable import/no-named-as-default-member */
// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable prefer-rest-params */
import isFunction from 'lodash/isFunction';
import eventing from '../../helpers/eventing';
import createLogger from '../../helpers/log';
import OTErrorClass from '../../ot/ot_error_class';
import RumorMessageTypes from '../rumor/RumorMessageTypes';
import unboxFromRumorMessage from './unboxFromRumorMessage';

const logging = createLogger('Dispatcher');

const Dispatcher = function () {
  eventing(this);
  this.callbacks = {};
};

Dispatcher.prototype.registerCallback = function (transactionId, completion) {
  this.callbacks[transactionId] = completion;
};

Dispatcher.prototype.triggerCallback = function (transactionId) {
  /* , arg1, arg2, argN-1, argN */
  if (!transactionId) { return; }

  const completion = this.callbacks[transactionId];

  if (completion && isFunction(completion)) {
    const args = Array.prototype.slice.call(arguments);
    args.shift();

    completion(...args);
  }

  delete this.callbacks[transactionId];
};

Dispatcher.prototype.onClose = function (reason) {
  this.emit('close', reason);
};

Dispatcher.prototype.onReconnected = function () {
  this.emit('reconnected');
};

Dispatcher.prototype.onReconnecting = function () {
  this.emit('reconnecting');
};

Dispatcher.prototype.dispatch = function (rumorMessage) {
  // The special casing of STATUS messages is ugly. Need to think about
  // how to better integrate this.

  if (rumorMessage.type === RumorMessageTypes.STATUS) {
    logging.debug(rumorMessage);

    let error;

    if (rumorMessage.isError) {
      let message;
      if (typeof rumorMessage.data === 'string') {
        try {
          const data = JSON.parse(rumorMessage.data);
          if (data && typeof data === 'object') {
            message = data.reason;
          }
        } catch (e) {
          logging.warn('Failed to parse rumorMessage.data', e);
        }
      }
      error = new OTErrorClass(rumorMessage.status, message);
    }
    const data = rumorMessage.data ? JSON.parse(rumorMessage.data) : '';
    if (data?.mute?.active) {
      this.dispatchMuteOnEntry();
    }

    this.triggerCallback(rumorMessage.transactionId, error, rumorMessage);

    return;
  }

  const message = unboxFromRumorMessage(rumorMessage);
  logging.debug(`${message.signature}:`, message);

  switch (message.resource) {
    case 'session':
      this.dispatchSession(message);
      break;

    case 'connection':
      // We do not want to expose backend auriga messages to end users
      if (message?.content?.userAgent?.includes('auriga')) {
        return;
      }
      this.dispatchConnection(message);
      break;

    case 'stream':
      this.dispatchStream(message);
      break;

    case 'stream_channel':
      this.dispatchStreamChannel(message);
      break;

    case 'subscriber':
      this.dispatchSubscriber(message);
      break;

    case 'subscriber_channel':
      this.dispatchSubscriberChannel(message);
      break;

    case 'signal':
      this.dispatchSignal(message);
      break;

    // Data may at some point in the future be used for non caption purposes
    case 'data':
      this.dispatchCaption(message);
      break;

    case 'archive':
      this.dispatchArchive(message);
      break;

    case 'source':
      this.dispatchSource(message);
      break;

    default:
      logging.debug(
        `Type ${message.resource} is not currently implemented`
      );
  }
};

Dispatcher.prototype.dispatchSession = function (message) {
  switch (message.method) {
    case 'read':
      this.emit('session#read', message.content, message.transactionId);
      break;

    case 'update':
      this.emit('session#update', message.content);
      break;

    default:
      logging.debug(`${message.signature} is not currently implemented`);
  }
};

Dispatcher.prototype.dispatchMuteOnEntry = function () {
  this.emit('session#muted');
};

Dispatcher.prototype.dispatchConnection = function (message) {
  switch (message.method) {
    case 'created':
      this.emit('connection#created', message.content);
      break;

    case 'deleted':
      this.emit('connection#deleted', message.params.connection, message.reason);
      break;

    default:
      logging.debug(`${message.signature} is not currently implemented`);
  }
};

Dispatcher.prototype.dispatchStream = function (message) {
  switch (message.method) {
    case 'created':
      this.emit('stream#created', message.content, message.transactionId);
      break;

    case 'deleted':
      this.emit('stream#deleted', message.params.stream,
        message.reason, message.content);
      break;

    case 'updated':
      this.emit('stream#updated', message.params.stream,
        message.content);
      break;

    case 'update':
      this.emit('stream#update', message.params.stream,
        message.content);
      break;

    // The JSEP process
    case 'generateoffer':
    case 'answer':
    case 'pranswer':
    case 'offer':
    case 'candidate':
      this.dispatchJsep(message.method, message);
      break;

    default:
      logging.debug(`${message.signature} is not currently implemented`);
  }
};

Dispatcher.prototype.dispatchStreamChannel = function (message) {
  switch (message.method) {
    case 'updated':
      this.emit('streamChannel#updated', message.params.stream,
        message.params.channel, message.content);
      break;

    default:
      logging.debug(`${message.signature} is not currently implemented`);
  }
};

// Dispatch JSEP messages
//
// generateoffer:
// Request to generate a offer for another Peer (or Prism). This kicks
// off the JSEP process.
//
// answer:
// generate a response to another peers offer, this contains our constraints
// and requirements.
//
// pranswer:
// a provisional answer, i.e. not the final one.
//
// candidate
//
//
Dispatcher.prototype.dispatchJsep = function (method, message) {
  this.emit(`jsep#${method}`, message.params.stream, message.fromAddress, message);
};

Dispatcher.prototype.dispatchSubscriberChannel = function (message) {
  switch (message.method) {
    case 'updated':
      this.emit('subscriberChannel#updated', message.params.stream,
        message.params.channel, message.content);
      break;

    case 'update': // subscriberId, streamId, content
      this.emit('subscriberChannel#update', message.params.subscriber,
        message.params.stream, message.content);
      break;

    default:
      logging.debug(`${message.signature} is not currently implemented`);
  }
};

Dispatcher.prototype.dispatchSubscriber = function (message) {
  switch (message.method) {
    case 'created':
      this.emit('subscriber#created', message.params.stream, message.fromAddress,
        message.content.id);
      break;

    case 'deleted':
      this.dispatchJsep('unsubscribe', message);
      this.emit('subscriber#deleted', message.params.stream,
        message.fromAddress);
      break;

    // The JSEP process
    case 'generateoffer':
    case 'answer':
    case 'pranswer':
    case 'offer':
    case 'candidate':
      this.dispatchJsep(message.method, message);
      break;

    default:
      logging.debug(`${message.signature} is not currently implemented`);
  }
};

Dispatcher.prototype.dispatchSignal = function (message) {
  if (message.method !== 'signal') {
    logging.debug(`${message.signature} is not currently implemented`);
    return;
  }
  this.emit('signal', message.fromAddress, message.content);
};

Dispatcher.prototype.dispatchCaption = function (message) {
  this.emit('caption', message.fromAddress, message.content);
};

Dispatcher.prototype.dispatchArchive = function (message) {
  switch (message.method) {
    case 'created':
      this.emit('archive#created', message.content);
      break;

    case 'updated':
      this.emit('archive#updated', message.params.archive, message.content);
      break;

    default:
  }
};

Dispatcher.prototype.dispatchSource = function (message) {
  switch (message.method) {
    case 'create':
      this.emit('source#create',
        message.params.source, message.params.stream, message.reason);
      break;

    case 'delete':
      this.emit('source#delete',
        message.params.source, message.params.stream, message.reason);
      break;

    default:
      logging.debug(`${message.signature} is not currently implemented`);
  }
};

export default Dispatcher;
