// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-underscore-dangle */
import promisify from './promisify';

/*
 * Process incoming Ice Candidates from a remote connection.
 *
 * @constructor
 * @private
 *
 * @param {PeerConection} peerConnection The PeerConnection we want to add the
 * Ice Candidates to.
 *
 * @description The Ice Candidates cannot be processed until the PeerConnection
 * has local and remote descriptions, if candidates are added too early they
 * will be put in a pending list. Once the PeerConnection is ready the pending
 * candidates will be applied.
 *
 * **note:** We assume that the PeerConnection has implemented the promisified
 * versions of addIceCandidate.
 *
 *
 * @example
 *
 *  const iceProcessor = new IceCandidateProcessor(pc);
 *  iceProcessor.process(iceMessage1);
 *  iceProcessor.process(iceMessage2);
 *  iceProcessor.process(iceMessage3);
 *
 *
 */
const IceCandidateProcessor = function IceCandidateProcessor(peerConnection) {
  this.pending = [];
  this._processBinding = this.maybeProcessPending.bind(this);

  if (peerConnection) {
    this.setPeerConnection(peerConnection);
  }
};

IceCandidateProcessor.prototype.setPeerConnection = function setPeerConnection(peerConnection) {
  if (this.pc) {
    throw new Error('Peer connection already set');
  }

  this.pc = peerConnection;
  this._forwardIceCandidate = promisify(this.pc, 'addIceCandidate');
  this.pc.addEventListener('signalingstatechange', this._processBinding);
  this.maybeProcessPending();
};

/**
 * Returns true if the PeerConnection is ready to accept IceCandidates.
 *
 * @return {Boolean} True if IceCandidates can be applied, false otherwise
 *
 * @method #canAddCandidates
 * @memberof IceCandidateProcessor
 *
 */
IceCandidateProcessor.prototype.canAddCandidates = function canAddCandidates() {
  // localDescription and removeDescription are nullable, so you would expect
  // that they would be null when those descriptions have not yet been applied
  // Chrome (as of 48) seems to instead give you a RTCSessionDescription with
  // type and sdp properties set to "".
  //
  // Because of this we need to ensure that type exists and contains something.
  //
  // We first check that the peer connection is not closed to prevent
  // Firefox from throwing an InvalidStateError. See OPENTOK-29358
  //
  return !!(this.pc && this.pc.iceConnectionState !== 'closed'
    && this.pc.remoteDescription && this.pc.remoteDescription.type
    && this.pc.localDescription && this.pc.localDescription.type);
};

/**
 * Add an RTCIceCandidate to the PeerConnection. If the PeerConnection is ready
 * it will be applied immediately, otherwise it will be buffered until later.
 *
 * @param {RTCIceCandidate} iceCandidate The candidate to add.
 *
 * @returns {Promise} A promise that is resolved when the candidate is applied.
 *
 * @method #addIceCandidate
 * @memberof IceCandidateProcessor
 *
 */
IceCandidateProcessor.prototype.addIceCandidate = function addIceCandidate(iceCandidate) {
  if (this.canAddCandidates()) {
    return this._forwardIceCandidate(iceCandidate);
  }

  const pending = this.pending;

  return new Promise((resolve, reject) => {
    pending.push({
      iceCandidate,
      resolve,
      reject,
    });
  });
};

/**
 * Called to attempt to process any pending candidates.
 *
 * @description This will be called automatically when needed so there's no
 * reason to call it yourself. You totally could, but surely you have better
 * things to do?
 *
 * @param {RTCIceCandidate} iceCandidate The candidate to add.
 *
 * @returns {Promise} A promise that is resolved when the candidate is applied.
 *
 * @method #addIceCandidate
 * @memberof IceCandidateProcessor
 *
 */
IceCandidateProcessor.prototype.maybeProcessPending = function maybeProcessPending() {
  if (this.canAddCandidates()) {
    this.process();
  }
};

IceCandidateProcessor.prototype.process = function process() {
  while (this.pending.length) {
    const pending = this.pending.shift();
    this._forwardIceCandidate(pending.iceCandidate)
      .then(pending.resolve)
      .catch(pending.reject);
  }
};

IceCandidateProcessor.prototype.destroy = function destroy() {
  if (this.pc) {
    this.pc.removeEventListener('signalingstatechange', this._processBinding);
  }

  this._processBinding = null;
  this.pc = null;
};

export default IceCandidateProcessor;
