// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-shadow, no-underscore-dangle, max-len, no-use-before-define */
/* eslint-disable no-void, vars-on-top, no-var, no-restricted-syntax, no-prototype-builtins */
/* eslint-disable no-continue */
import eventing from '../helpers/eventing';
import createLogger from '../helpers/log';
import sessionObjects from './session/objects';
import OTHelpers from '../common-js-helpers/OTHelpers';
import EventsFactory from './events';

const logging = createLogger('Stream');

const Events = EventsFactory();

const validPropertyNames = ['name', 'archiving'];

/**
 * Specifies a stream. A stream is a representation of a published stream in a session. When a
 * client calls the <a href="Session.html#publish">Session.publish() method</a>, a new stream is
 * created. Properties of the Stream object provide information about the stream.
 *
 *  <p>When a stream is added to a session, the Session object dispatches a
 * <code>streamCreatedEvent</code>. When a stream is destroyed, the Session object dispatches a
 * <code>streamDestroyed</code> event. The StreamEvent object, which defines these event objects,
 * has a <code>stream</code> property, which is an array of Stream object. For details and a code
 * example, see {@link StreamEvent}.</p>
 *
 *  <p>When a connection to a session is made, the Session object dispatches a
 * <code>sessionConnected</code> event, defined by the SessionConnectEvent object. The
 * SessionConnectEvent object has a <code>streams</code> property, which is an array of Stream
 * objects pertaining to the streams in the session at that time. For details and a code example,
 * see {@link SessionConnectEvent}.</p>
 *
 * @class Stream
 * @property {Connection} connection The Connection object corresponding
 * to the connection that is publishing the stream. You can compare this to the
 * <code>connection</code> property of the Session object to see if the stream is being published
 * by the local web page.
 *
 * @property {Number} creationTime The timestamp for the creation
 * of the stream. This value is calculated in milliseconds. You can convert this value to a
 * Date object by calling <code>new Date(creationTime)</code>, where <code>creationTime</code> is
 * the <code>creationTime</code> property of the Stream object.
 *
 * @property {Number} frameRate The frame rate of the video stream. This property is only set if
 * the publisher of the stream specifies a frame rate when calling the
 * <code>OT.initPublisher()</code> method; otherwise, this property is undefined.
 *
 * @property {Boolean} hasAudio Whether the stream has audio. This property can change if the
 * publisher turns on or off audio (by calling
 * <a href="Publisher.html#publishAudio">Publisher.publishAudio()</a>). When this occurs, the
 * {@link Session} object dispatches a <code>streamPropertyChanged</code> event (see
 * {@link StreamPropertyChangedEvent}).
 *
 * @property {Boolean} hasVideo Whether the stream has video. This property can change if the
 * publisher turns on or off video (by calling
 * <a href="Publisher.html#publishVideo">Publisher.publishVideo()</a>). When this occurs, the
 * {@link Session} object dispatches a <code>streamPropertyChanged</code> event (see
 * {@link StreamPropertyChangedEvent}).
 *
 * @property {String} initials The initials for the stream. Publishers can specify the initials
 * to be displayed when the video is disabled.
 * (See the <code>initials</code> property of the <code>properties</code> parameter passed
 * into the <a href="OT.html#initPublisher">OT.initPublisher()</a> method.)
 *
 * @property {String} name The name of the stream. Publishers can specify a name when publishing
 * a stream (using the <code>publish()</code> method of the publisher's Session object).
 *
 * @property {String} streamId The unique ID of the stream.
 *
 * @property {Object} videoDimensions This object has two properties: <code>width</code> and
 * <code>height</code>. Both are numbers. The <code>width</code> property is the width of the
 * encoded stream; the <code>height</code> property is the height of the encoded stream. (These
 * are independent of the actual width of Publisher and Subscriber objects corresponding to the
 * stream.) This property can change if a stream published from a mobile device resizes, based on
 * a change in the device orientation. When the video dimensions change,
 * the {@link Session} object dispatches a <code>streamPropertyChanged</code> event
 * (see {@link StreamPropertyChangedEvent}).
 *
 * @property {String} videoType The type of video &mdash; either <code>"camera"</code>,
 * <code>"screen"</code>, or <code>"custom"</code>.
 * A <code>"screen"</code> video uses screen sharing on the publisher
 * as the video source; for other videos, this property is set to <code>"camera"</code>.
 * A <code>"custom"</code> video uses a VideoTrack element as the video source on the publisher.
 * (See the <code>videoSource</code> property of the <code>options</code> parameter passed
 * into the <a href="OT.html#initPublisher">OT.initPublisher()</a> method.)
 * The <code>videoType</code> is <code>undefined</code> when a stream is voice-only
 * (see the <a href="https://tokbox.com/developer/guides/audio-video/js/#voice">Voice-only guide</a>).
 * This property can change if a stream published from a mobile device changes from a
 * camera to a screen-sharing video type. When the video type changes, the {@link Session} object
 * dispatches a <code>streamPropertyChanged</code> event (see {@link StreamPropertyChangedEvent}).
 */

export default function Stream(id, name, creationTime, connection, session, channel, initials) {
  const self = this;
  let destroyedReason;
  this.id = id;
  this.streamId = id;
  this.name = name;
  this.creationTime = Number(creationTime);
  this.initials = initials || '';

  this.connection = connection;
  this.channel = channel;
  this.publisher = sessionObjects.publishers.find({ streamId: this.id });

  eventing(this);

  const onChannelUpdate = function onChannelUpdate(channel, key, oldValue, newValue) {
    let _key = key;
    switch (_key) {
      case 'active':
        _key = channel.type === 'audio' ? 'hasAudio' : 'hasVideo';
        self[_key] = newValue;
        break;

      case 'disableWarning':
        _key = channel.type === 'audio' ? 'audioDisableWarning' : 'videoDisableWarning';
        self[_key] = newValue;
        if (!self[channel.type === 'audio' ? 'hasAudio' : 'hasVideo']) {
          return; // Do NOT event in this case.
        }
        break;

      case 'fitMode':
        _key = 'defaultFitMode';
        self[_key] = newValue;
        break;

      case 'source':
        _key = channel.type === 'audio' ? 'audioType' : 'videoType';
        self[_key] = newValue;
        break;

      case 'videoDimensions':
        self.videoDimensions = newValue;
        break;

      case 'orientation':
      case 'width':
      case 'height':
        // We dispatch this via the videoDimensions key instead so do not
        // trigger an event for them.
        return;

      default:
    }

    self.dispatchEvent(new Events.StreamUpdatedEvent(self, _key, oldValue, newValue));
  };

  const associatedWidget = function associatedWidget() {
    if (self.publisher) {
      return self.publisher;
    }

    return sessionObjects.subscribers.find(subscriber => subscriber.stream && subscriber.stream.id === self.id &&
        subscriber.session.id === session.id);
  };

  // Returns true if this stream is subscribe to.
  const isBeingSubscribedTo = function isBeingSubscribedTo() {
    // @fixme This is not strictly speaking the right test as a stream
    // can be published and subscribed by the same connection. But the
    // update features don't handle this case properly right now anyway.
    //
    // The issue is that the stream needs to know whether the stream is
    // 'owned' by a publisher or a subscriber. The reason for that is that
    // when a Publisher updates a stream channel then we need to send the
    // `streamChannelUpdate` message, whereas if a Subscriber does then we
    // need to send `subscriberChannelUpdate`. The current code will always
    // send `streamChannelUpdate`.
    return !self.publisher;
  };

  // Returns all channels that have a type of +type+.
  this.getChannelsOfType = function getChannelsOfType(type) {
    return self.channel.filter(channel => channel.type === type);
  };

  this.getChannel = function getChannel(id) {
    for (let i = 0; i < self.channel.length; ++i) {
      if (self.channel[i].id === id) { return self.channel[i]; }
    }

    return null;
  };

  // implement the following using the channels
  // * hasAudio
  // * hasVideo
  // * videoDimensions

  const audioChannel = this.getChannelsOfType('audio')[0];
  const videoChannel = this.getChannelsOfType('video')[0];

  // @todo this should really be: "has at least one video/audio track" instead of
  // "the first video/audio track"
  this.hasAudio = audioChannel != null && audioChannel.active;
  this.hasVideo = videoChannel != null && videoChannel.active;

  this.videoType = videoChannel && videoChannel.source;
  this.defaultFitMode = videoChannel && videoChannel.fitMode;

  this.videoDimensions = {};

  if (videoChannel) {
    this.videoDimensions.width = videoChannel.width;
    this.videoDimensions.height = videoChannel.height;
    this.videoDimensions.orientation = videoChannel.orientation;

    videoChannel.on('update', onChannelUpdate);
    this.frameRate = videoChannel.frameRate;
  }

  if (audioChannel) {
    audioChannel.on('update', onChannelUpdate);
  }

  this.setChannelActiveState = function setChannelActiveState(channelType, activeState, activeReason, congestionLevel, subscriberId) {
    const attributes = {
      active: activeState,
    };
    if (activeReason) {
      attributes.activeReason = activeReason;
    }
    if (congestionLevel !== undefined) {
      attributes.congestionLevel = congestionLevel;
    }
    if (subscriberId) {
      attributes.subscriberId = subscriberId;
    }
    updateChannelsOfType(channelType, attributes);
  };

  this.setVideoDimensions = function setVideoDimensions(width, height) {
    updateChannelsOfType('video', {
      width,
      height,
      orientation: 0,
    });
  };

  this.setRestrictFrameRate = function setRestrictFrameRate(restrict) {
    updateChannelsOfType('video', {
      restrictFrameRate: restrict,
    });
  };

  this.setPreferredResolution = function setPreferredResolution(resolution) {
    if (!isBeingSubscribedTo()) {
      logging.warn('setPreferredResolution has no affect when called by a publisher');
      return;
    }

    if (session.sessionInfo.p2pEnabled) {
      logging.warn('Stream.setPreferredResolution will not work in a P2P Session');
      return;
    }

    if (resolution &&
        resolution.width === void 0 &&
        resolution.height === void 0) {
      return;
    }

    // This duplicates some of the code in updateChannelsOfType. We do this for a
    // couple of reasons:
    //   1. Because most of the work that updateChannelsOfType does is in calling
    //      getChannelsOfType, which we need to do here anyway so that we can update
    //      the value of maxResolution in the Video Channel.
    //   2. updateChannelsOfType on only sends a message to update the channel in
    //      Rumor. The client then expects to receive a subsequent channel update
    //      indicating that the update was successful. We don't receive those updates
    //      for preferredFrameRate/maxResolution so we need to complete both tasks and it's
    //      neater to do the related tasks right next to each other.
    //   3. This code shouldn't be in Stream anyway. There is way too much coupling
    //      between Stream, Session, Publisher, and Subscriber. This will eventually be
    //      fixed, and when it is then it will be easier to exact the code if it's a
    //      single piece.
    //
    const video = self.getChannelsOfType('video')[0];
    if (!video) {
      return;
    }

    if (resolution && resolution.width) {
      if (isNaN(parseInt(resolution.width, 10))) {
        throw new OTHelpers.Error('stream preferred width must be an integer', 'Subscriber');
      }

      video.preferredWidth = parseInt(resolution.width, 10);
    } else {
      video.preferredWidth = void 0;
    }

    if (resolution && resolution.height) {
      if (isNaN(parseInt(resolution.height, 10))) {
        throw new OTHelpers.Error('stream preferred height must be an integer', 'Subscriber');
      }

      video.preferredHeight = parseInt(resolution.height, 10);
    } else {
      video.preferredHeight = void 0;
    }

    session._.subscriberChannelUpdate(self, associatedWidget(), video, {
      preferredWidth: video.preferredWidth || 0,
      preferredHeight: video.preferredHeight || 0,
    });
  };

  this.getPreferredResolution = function getPreferredResolution() {
    const videoChannel = self.getChannelsOfType('video')[0];
    if (!videoChannel || (!videoChannel.preferredWidth && !videoChannel.preferredHeight)) {
      return void 0;
    }

    return {
      width: videoChannel.preferredWidth,
      height: videoChannel.preferredHeight,
    };
  };

  this.setPreferredFrameRate = function setPreferredFrameRate(preferredFrameRate) {
    if (!isBeingSubscribedTo()) {
      logging.warn('setPreferredFrameRate has no affect when called by a publisher');
      return;
    }

    if (session.sessionInfo.p2pEnabled) {
      logging.warn('Stream.setPreferredFrameRate will not work in a P2P Session');
      return;
    }

    if (preferredFrameRate && isNaN(parseFloat(preferredFrameRate))) {
      throw new OTHelpers.Error('stream preferred frameRate must be a number', 'Subscriber');
    }

    // This duplicates some of the code in updateChannelsOfType. We do this for a
    // couple of reasons:
    //   1. Because most of the work that updateChannelsOfType does is in calling
    //      getChannelsOfType, which we need to do here anyway so that we can update
    //      the value of preferredFrameRate in the Video Channel.
    //   2. updateChannelsOfType on only sends a message to update the channel in
    //      Rumor. The client then expects to receive a subsequent channel update
    //      indicating that the update was successful. We don't receive those updates
    //      for preferredFrameRate/maxResolution so we need to complete both tasks and it's
    //      neater to do the related tasks right next to each other.
    //   3. This code shouldn't be in Stream anyway. There is way too much coupling
    //      between Stream, Session, Publisher, and Subscriber. This will eventually be
    //      fixed, and when it is then it will be easier to exact the code if it's a
    //      single piece.
    //
    const video = self.getChannelsOfType('video')[0];

    if (video) {
      video.preferredFrameRate = preferredFrameRate ? parseFloat(preferredFrameRate) : null;

      session._.subscriberChannelUpdate(self, associatedWidget(), video, {
        preferredFrameRate: video.preferredFrameRate || 0,
      });
    }
  };

  this.getPreferredFrameRate = function getPreferredFrameRate() {
    const videoChannel = self.getChannelsOfType('video')[0];
    return videoChannel ? videoChannel.preferredFrameRate : null;
  };

  let updateChannelsOfType = function updateChannelsOfType(channelType, attributes) {
    let setChannelActiveState;
    if (!self.publisher) {
      const subscriber = associatedWidget();

      setChannelActiveState = channel =>
        session._.subscriberChannelUpdate(self, subscriber, channel, attributes);
    } else {
      setChannelActiveState = channel =>
        session._.streamChannelUpdate(self, channel, attributes);
    }

    self.getChannelsOfType(channelType).forEach(setChannelActiveState);
  };

  this.destroyed = false;
  this.destroyedReason = void 0;

  this.destroy = function destroy(reason = 'clientDisconnected', quiet) {
    destroyedReason = reason;
    self.destroyed = true;
    self.destroyedReason = destroyedReason;

    if (quiet !== true) {
      self.dispatchEvent(
        new Events.DestroyedEvent(
          'destroyed', // This should be eventNames.STREAM_DESTROYED, but
          // the value of that is currently shared with Session
          self,
          destroyedReason
        )
      );
    }
  };

  // PRIVATE STUFF CALLED BY Raptor.Dispatcher
  //
  // Confusingly, this should not be called when you want to change
  // the stream properties. This is used by Raptor dispatch to notify
  // the stream that it's properties have been successfully updated
  //
  // @todo make this sane. Perhaps use setters for the properties that can
  // send the appropriate Raptor message. This would require that Streams
  // have access to their session.

  this._ = {};
  this._.updateProperty = function privateUpdateProperty(key, value) {
    if (validPropertyNames.indexOf(key) === -1) {
      logging.warn(`Unknown stream property "${key}" was modified to "${value}".`);
      return;
    }

    const oldValue = self[key];
    const newValue = value;

    switch (key) {
      case 'name':
        self[key] = newValue;
        break;

      case 'archiving':
        var widget = associatedWidget();
        if (self.publisher && widget) {
          widget._.archivingStatus(newValue);
        }
        self[key] = newValue;
        break;

      default:
    }

    const event = new Events.StreamUpdatedEvent(self, key, oldValue, newValue);
    self.dispatchEvent(event);
  };

  // Mass update, called by Raptor.Dispatcher
  this._.update = function privateUpdate(attributes) {
    for (const key in attributes) {
      if (!attributes.hasOwnProperty(key)) {
        continue;
      }
      self._.updateProperty(key, attributes[key]);
    }
  };

  this._.forceMute = function forceMute(content) {
    if (content.hasOwnProperty('channels')) {
      if (content.channels.includes('audio')) {
        if (self.publisher) {
          self.publisher._.forceMuteAudio();
        }
      }
    }
  };

  this._.updateChannel = function privateUpdateChannel(channelId, attributes) {
    const channel = self.getChannel(channelId);
    if (channel) {
      channel.update(attributes);
    }
  };
}
