/* eslint-disable no-underscore-dangle */
import EventEmitter from 'events';
import createRecoveryCongestionLevelCalculatorDefault from './recoveryCongestionLevelCalculator';
import { CRITICAL, LOW } from '../congestionLevels';
import createLogger from '../../../../helpers/log';
import { ONE_SECOND_IN_MS } from '../../../../common-js-helpers/time';

const logger = createLogger('networkProbe');

const MAX_PROBE_TIME = 10 * ONE_SECOND_IN_MS;

const createNetworkProbe = (deps = {}) => {
  const ee = new EventEmitter();
  const {
    getStats,
    createRecoveryCongestionLevelCalculator = createRecoveryCongestionLevelCalculatorDefault,
  } = deps;

  let probeStartTime;
  const congestionLevelCalculator = createRecoveryCongestionLevelCalculator({ getStats });

  const stopProbeWithResult = (result) => {
    congestionLevelCalculator.stop();
    logger.info(`Network probe result ${result}`);
    ee.emit('probeResult', result);
  };

  congestionLevelCalculator.on('congestionLevel', (congestionLevel) => {
    const probeTime = Date.now() - probeStartTime;
    if (congestionLevel === CRITICAL) {
      // We stop probing immediately if we get a CRITICAL result
      stopProbeWithResult(CRITICAL);
    } else if (probeTime > MAX_PROBE_TIME) {
      if (congestionLevel === LOW) {
        stopProbeWithResult(LOW);
      } else {
        // final measurement result was MEDIUM so we keep CRITICAL level
        stopProbeWithResult(CRITICAL);
      }
    }
  });

  return Object.assign(ee, {
    start() {
      probeStartTime = Date.now();
      logger.info('Start network probe');
      congestionLevelCalculator.start();
    },
    stop() {
      logger.info('Stop network probe');
      congestionLevelCalculator.stop();
    },
  });
};

export default createNetworkProbe;
