import SDPHelpersDefault from './sdp_helpers';

const createPeerConnectionSDP = (deps = {}) => {
  const sdpHelpers = deps.helpers || SDPHelpersDefault;
  const peerConnectionSDP = {
    sdp: {
      mids: [],
      mediaSections: [],
      headers: [],
      bundle: null,
      version: null,
      tracks: [],
    },
    subscribePcIdsByMid: {},

    updateSdp(sdp) {
      const parsedSdp = sdpHelpers.parseMantisSDP(sdp);
      Object.assign(this.sdp, parsedSdp);
    },

    updateSdpWithNewOffer(sdp) {
      const parsedSdp = sdpHelpers.updateSDPWithNewOffer({
        mediaSections: this.sdp.mediaSections,
        headers: this.sdp.headers,
        version: this.sdp.version,
      }, sdp.sdp);
      Object.assign(this.sdp, parsedSdp);
    },

    setOfferAndCreateAnswer(offer) {
      this.updateSdpWithNewOffer(offer);
      return sdpHelpers.createSDP(this.sdp.headers, this.sdp.mediaSections);
    },

    addSubscriberMid(mid, subscriberPcId) {
      this.subscribePcIdsByMid[mid] = subscriberPcId;
    },

    removeSubscriberMids(subscriberPcId) {
      const midsToRemove = Object.keys(this.subscribePcIdsByMid)
        .filter(mid => this.subscribePcIdsByMid[mid] === subscriberPcId);

      midsToRemove.forEach(mid => delete this.subscribePcIdsByMid[mid]);
    },

    isHead(subscriberPcId) {
      const headMid = this.sdp.mids.find(mid => mid !== null);
      return this.subscribePcIdsByMid[headMid] === subscriberPcId;
    },
  };

  return peerConnectionSDP;
};

export default createPeerConnectionSDP;
